import React, { type FC } from 'react';
import {
  ImageHoverEffectOptions as HoverEffectOptions,
  ImageLoadingBehaviorOptions as LoadingBehaviorOptions,
  ImageResizeOptions as ResizeOptions,
  ThumbnailImage,
} from 'wix-ui-tpa/cssVars';
import { ImageResizeOptions } from '../../../../../../types/types';
import { useWidgetViewModel } from '../../../hooks/useWidgetViewModel';
import { classes, st } from './LayoutImage.st.css';
import { DataHooks } from './consts';
import { Image } from '@wix/ambassador-bookings-services-v2-service/types';

export type LayoutImageProps = {
  image?: Image;
  imageTitle: string;
  width: number;
  height: number;
  onClick(): void;
  className?: string;
};

const mapImageResizeOptionsToResizeOptions = {
  [ImageResizeOptions.CROP]: ResizeOptions.cover,
  [ImageResizeOptions.FIT]: ResizeOptions.contain,
};

export const LayoutImage: FC<LayoutImageProps> = (props) => {
  const { image, onClick, className } = props;
  const { isSEO, serviceImageViewModel } = useWidgetViewModel();
  const { imageResize, focalPoint } = serviceImageViewModel;

  return (
    <div data-hook={DataHooks.ROOT} className={st(classes.root, className)}>
      <div data-hook={DataHooks.WRAPPER} className={classes.wrapper}>
        <div
          data-hook={DataHooks.CONTAINER}
          onClick={onClick}
          className={classes.clickable}
          role="presentation"
          tabIndex={-1}
        >
          {image ? (
            <ThumbnailImage
              alt={image.altText || undefined}
              fluid
              width={isSEO ? image.width : 50}
              height={isSEO ? image.height : 50}
              focalPoint={focalPoint}
              loadingBehavior={LoadingBehaviorOptions.none}
              hoverEffect={HoverEffectOptions.darken}
              resize={mapImageResizeOptionsToResizeOptions[imageResize]}
              data-hook={DataHooks.IMAGE}
              className={classes.image}
              src={image.url}
              sourceHeight={image.height}
              sourceWidth={image.width}
              shouldUseLQIP
              isSEOBot={isSEO}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
